import React, {Component} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Card extends Component{
     constructor(props){
        super(props);
        this.state = {
            file : []
        }
    }
    render() {
    return(
        <>
        <div className="col-3">
            <div className="card" style={{width:'18rem', textAlign:'center', margin:'20px', minHeight: '350px'}}>
            <div>
            <FontAwesomeIcon icon={this.props.card.icon} size="5x" style={{padding:"20px"}}></FontAwesomeIcon>
            </div>
                <div className="card-body text-center">
                    <p>{this.props.card.p}</p>
                    <input onChange={(e)=> {
                    this.setState({file: e.target.files[0]});}} type="file"></input>  
                    <div className="mt-4">
                        <button type="button" className="btn btn-primary" onClick={()=> this.props.onUpload(this.state.file)}> 
                        Upload
                        </button> 
                    </div>
                </div>
            </div>
        </div>
        </>
    );
    }
}

export default Card;

