import React, {useState, useEffect} from "react";
import DataTable, {ExpanderComponentProps}  from "react-data-table-component";
import { faFilter, faSearch, faPlus, faArrowRight  } from '@fortawesome/free-solid-svg-icons';
import 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantTable = () => {
    const [mutationsData, setMutationsData] = useState([])
    const [fusionsData, setFusionsData] = useState([])
    const [pending, setPending] = React.useState(true);
    const fixedHeader = true;
    
    const getdata = async () => {
        setPending(true);
        let url = '/api/sample/GetForVariantBrowser?gene='; 

        const gene = document.getElementById("filtroGene").value;
        const hgvsp = document.getElementById("filtroHgvsp").value;
       
        if(gene !== undefined && gene !== null && gene !== ''){
            url += gene;
        }
    
        url += '&variant=';
        if(hgvsp !== undefined && hgvsp != null && hgvsp !== ''){
            url += hgvsp;
        }

        const response = await fetch(url);
        const data = await response.json();
        
        setMutationsData(data.allvarianttypes);
        setFusionsData(data.fusionsandcnv);

        setPending(false);
    }

    useEffect(()=> {
        getdata()
    }, [])
  
    const columnsMutationsData = [
        // {
        //     name:  'Id',
        //     selector: row => row._id
        // },
        {
            name:  'Histology',
            selector: row => row.histology
        },
        {
            name:  'Site',
            selector: row => row.site
        },
        {
            name:  'Gene',
            selector: row =>  row.gene
        },
        {
            name:  'Chromosome',
            selector: row => row.chromosome
        },
        {
            name:  'Type',
            selector: row => row.variant_type
        },
        {
            name:  'Variant',
            selector: row => row.variant
        },
        {
            name:  'VAF',
            selector: row => parseFloat(row.vaf).toFixed(2)
        },
        {
            name:  'Alt',
            selector: row => row.alteration
        },
        {
            name:  'Ref',
            selector: row => row.reference
        }
    ]


    const columnsFusionsData = [
        {
            name:  'Histology',
            selector: row => row.histology
        },
        {
            name:  'Site',
            selector: row => row.site
        },
        {
            name:  'Gene',
            selector: row =>  row.gene
        },
        {
            name:  'Chromosome',
            selector: row => row.chromosome
        },
        {
            name:  'Type',
            selector: row => row.variant_type
        },
        {
            name:  'Alteration',
            selector: row => row.alteration
        },
        {
            name:  'Variant',
            selector: row => row.variant
        },
        {
            name:  'Depth',
            selector: row => row.depth
        }
        
    ]

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '1rem'
            },
        }
    }

    return (
        <>
        <h3 className="text-center">Variant Browser</h3>
        
        <div className="container my-3">
                <div className="mb-5">
                <fieldset className="border rounded-3 shadow" style={{margin:'auto'}}>
                    <legend style={{backgroundColor:'white', marginLeft:'1rem'}} > {/* className='px-2' */}
                        <FontAwesomeIcon icon={faFilter} style={{color:'var(--blue'}}></FontAwesomeIcon>
                        &nbsp;Filters
                    </legend>
                    
                        <div className="d-flex justify-content-center align-items-end mb-3">
                            <div className="me-2">
                                <label htmlFor="filtroGene" className="form-label ms-3" style={{fontWeight:'bold'}}  >Gene:</label>
                                <input type="text" className="form-control" id="filtroGene" placeholder="Type gene here..."/>
                            </div>
                            <div>
                                <label htmlFor="filtroHgvsp" className="form-label ms-3" style={{fontWeight:'bold'}}>Variant:</label>
                                <input type="text" className="form-control" id="filtroHgvsp" placeholder="Type variant here..."/>
                            </div>
                            <div>
                            <button type="button" onClick={getdata} id="filter-btn" className="btn btn-primary ms-2">
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>    
                                &nbsp;&nbsp;Search
                            </button>
                        </div>
                    </div>
                </fieldset>
            </div>
        
            <DataTable
            columns={columnsMutationsData}
            data ={mutationsData}
            progressPending={pending}
            pagination
            fixedHeader={fixedHeader}
            customStyles={customStyles}
            persistTableHead
            />

            <div className="my-5"></div>

            <DataTable
            columns={columnsFusionsData}
            data ={fusionsData}
            progressPending={pending}
            pagination
            fixedHeader={fixedHeader}
            customStyles={customStyles}
            persistTableHead
            />
        </div>
    </>);
}
export default VariantTable;