import logoIfo from '../img/logo-ifo-ire.png'
import React, {Component} from "react";

export default class Navbar extends Component {
    
    setActiveLink = () => {
        document.querySelectorAll('a.nav-link').forEach(
            link => {
                if(link.getAttribute('href') === window.location.pathname){
                    link.classList.add('underline');
                }
            }
        );
    }

    componentDidMount() {
        this.setActiveLink();
    }

    render(){
        return(
        <>
            <nav className="navbar navbar-light bg-light">
                <div className="container">
                    <div className='d-flex justify-content-between align-items-center'>
                        <div style={{fontSize:'1.2rem'}}>
                            <span style={{color:'var(--red)', fontWeight:'bold'}}>S</span>
                            omatic&nbsp;
                            <span style={{color:'var(--red)', fontWeight:'bold'}}>V</span>
                            ariant&nbsp;
                            <span style={{color:'var(--red)', fontWeight:'bold'}}>R</span>
                            egistry
                        </div>
                        <img src={logoIfo} style={{ width: '40%'}} />
                    </div>
                </div>
            </nav>
            <div style={{backgroundColor:'var(--blue'}} className='mb-5'>
                <div className='container py-1 d-flex'>
                    <a href='/' className='nav-link' style={{color:'white'}}>Home</a>
                    {/* <a href='/insert' className='nav-link' style={{color:'white'}}>Inserimento</a> 
                    <a href="/visualizzazione" className="nav-link" style={{color:'white'}}>Patient Browser</a>*/}
                    <a href="/visualization" className="nav-link" style={{color:'white'}}>Variant Browser</a>
                    <a href="/dataUsageConditions" className="nav-link" style={{color:'white'}}>Data Usage Conditions</a>
                </div>
            </div>
        </>)
   };
}