import React, {Component} from "react";

const DataUsage=() => {
    return <div className="container text-center">
        <h3>
            Data Usage Conditions
        </h3>
        <p>
        Public demo version: All data present in this website is obtained via random scrambling of somatic variants. 
        <br/>
        The interface is meant to represent a demonstrator of the Somatic Registry developed at IFO-IRE. 
        <br/>
        Regarding internal access, anonymized somatic molecular results are meant to be accessible to the institutional Data Warehouse (DWH). This access is controlled to a list of authorized researchers for GPDR accountability that do not have patient identity information. 
        <br/>
        The bioinformatics protocol has been released as open source software available on our <a href="https://gitlab.com/bioinfo-ire-release/mrt" target={"_blank"}>GitLab</a>.
        <br/>
        For all inquiries regarding the Somatic Registry: <a href="mailto:matteo.pallocca@ifo.it">matteo.pallocca@ifo.it</a> 
        </p>
    </div>;
}

export default DataUsage;