import Chart from "../components/chart";
import React, {Component} from "react";

class Home extends Component {
    render(){ 
        return(
        <div className="container">
        {
            <>
            <div className="d-flex justify-content-center">
            <Chart height={'800px'} width={'1000px'} chartId={'64158e53-82c5-4e61-83fd-93d03142c65a'}/>
            </div>
            <div className="d-flex justify-content-center my-4">
            <Chart height={'800px'} width={'1000px'} chartId={'64158472-0f27-45e7-824c-f290d8833fc7'}/>
            </div>
            <div className="d-flex justify-content-center my-4">
            <Chart height={'800px'} width={'1000px'} chartId={'64158b60-2d5a-454d-888c-97a07915e03c'}/>
            </div> 
            <div className="d-flex justify-content-center my-4">
            <Chart height={'800px'} width={'1000px'} chartId={'641589ca-7199-4570-80d6-1358cdfc328c'}/>
            </div>
            <div className="d-flex justify-content-center my-4">
            <Chart height={'800px'} width={'1000px'} chartId={'64158813-0f27-442d-889b-f290d8852c0e'}/>
            </div>
            </>
        }
        </div>);
    }
}

export default Home;