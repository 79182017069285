import Card from "../components/card";
import React, {Component} from "react";
import { faHospitalUser, faDna, faDatabase, faVialCircleCheck  } from '@fortawesome/free-solid-svg-icons';
class Insert extends Component {
  state = {
    cards:[
      {id:0, url:'/api/sample/', icon: faHospitalUser, p:'Upload clinical data (.tsv, .csv, .xlsx)'},
      {id:1, url:'/api/variant/', icon: faDna, p:'Upload sequencing data (.tsv, .csv, .xlsx)'},
      {id:2, url:'/api/panel', icon:faVialCircleCheck, p:'Upload the panels\' list (.tsv, .csv, .xlsx)'},
      {id:3, url:'/api/civic', icon:faDatabase, p:'Upload annotations data (.tsv, .csv, .xlsx)'}
    ]
  }

  handleUpdate(file){
    let formData = new FormData();
    formData.append('file', file);
    let url = this.card.url;
    
    fetch(url, {
      method: 'post',
      body: formData,
    })
    .then(response => response.json())
    .then(result => {
      //console.log('Success:', result);
      alert(result.message);
    })
    .catch(error => {
      //console.error('Error:', error);
      alert(error.message);
    });
  }
  render(){ 
    return(
      <div className="container">
        <div className="row" >
        {
          this.state.cards.map(card => (
            <Card
            key={card.id}
            card={card}
            onUpload = {this.handleUpdate}
            />))
        }
        </div>
    </div>
  )
  }
}

export default Insert;