import React, { Component } from "react";
import Navbar from "./components/navbar";
import DataUsage from "./components/data-usage"
import Insert from "./components/insert";
import Table from "./components/table";
import Home from "./components/home"
import VariantTable from "./components/variant-table"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
function App (){
  return (
    <>
        <Navbar />
        <BrowserRouter>
          <Routes>
            {/* <Route path="/insert" element={<Insert/>}></Route>   */}
            {/* <Route path="/patientvisualization" element={<Table/>}></Route>   */}
            <Route path="/visualization" element={<VariantTable/>}></Route>  
            <Route path="/dataUsageConditions" element={<DataUsage/>}></Route>  
            <Route path="/" element={<Home/>}></Route>
          </Routes> 
        </BrowserRouter>
    </>
  );
}

export default App;
